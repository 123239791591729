// 
// 
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

.decoration.scale-10 svg {
  transform: scale(10); 
}

.decoration.scale-8 svg {
  transform: scale(8); 
}

.decoration.scale-7 svg {
  transform: scale(7); 
}

.decoration.scale-6 svg {
  transform: scale(6); 
}

.opacity-0 {
  opacity:0!important;
}
.opacity-1 {
  opacity:0.2!important;
}
.opacity-2 {
  opacity:0.4!important;
}
.opacity-3 {
  opacity:0.6!important;
}
.opacity-4 {
  opacity:.8!important;
}
.opacity-5 {
  opacity:1!important;
}

/* Maybe even support hover opacity shifts */
.opacity-0h5 {
  opacity:0!important;
  transition: opacity .25s ease-in-out!important;
  -moz-transition: opacity .25s ease-in-out!important;
  -webkit-transition: opacity .25s ease-in-out!important;
}
.opacity-0h5:hover {
  opacity:1!important;
}

#experience {
  h5 {
    margin-left: 2.5rem;
  }
}

#covid {
  @extend .alert-primary;
  @extend .navbar-dark;
  // color: $primary-3;
  border-radius: 0;
}